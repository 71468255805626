import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { addMonths, isBefore } from 'date-fns';
import { combineLatest, filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { CompanyStateService } from '@dougs/company/shared';
import { URL } from '@dougs/core/routing';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { COGEP_ACCOUNTING_FIRM_ID } from '@dougs/settings/shared';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { UserTasksStateService } from '@dougs/task/shared';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';

@Injectable()
export class SidebarModuleAccessComponentService {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly accountingOnboardingStateService: AccountingOnboardingStateService,
    private readonly router: Router,
  ) {}

  isLoggedInUserAccountantOrAdmin$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => loggedInUser.isAccountantOrAdmin),
  );

  isCreationMode$: Observable<boolean> = this.companyStateService.activeCompany$.pipe(
    map((activeCompany) => activeCompany && !activeCompany.isCreated && !activeCompany.isAccountingSignupCompleted),
  );

  hideOpenMenuIcon$: Observable<boolean> = this.router.events.pipe(
    filter((event: Event): event is NavigationEnd => !!event && event instanceof NavigationEnd),
    map((event) => event.url),
    map((url) => url.includes(URL.SETTINGS_NEW)),
  );

  isDougsCompanyAndHasDougsInvoicingFlag$: Observable<boolean> = combineLatest([
    this.userStateService.loggedInUser$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    map(
      ([loggedInUser, activeCompany]) => activeCompany?.id === 125 && !!loggedInUser?.flags.includes('dougsInvoicing'),
    ),
  );

  isAccountingServiceStateTerminated$: Observable<boolean> = this.companyServicesStateService.services$.pipe(
    map((services) => services.accounting.state === SERVICE_STATE.TERMINATED),
  );

  hasAccountingService$: Observable<boolean> = this.companyServicesStateService.services$.pipe(
    map((services) =>
      [
        SERVICE_STATE.WAITING,
        SERVICE_STATE.ONBOARDING,
        SERVICE_STATE.PENDING,
        SERVICE_STATE.ACTIVATED,
        SERVICE_STATE.SUSPENDED,
        SERVICE_STATE.OFFBOARDING,
        SERVICE_STATE.TERMINATED,
        SERVICE_STATE.UNKNOWN,
      ].includes(services.accounting.state),
    ),
  );

  shouldShowAccountingLinkForBlankSlate$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.hasAccountingService$,
    this.isAccountingServiceStateTerminated$,
  ]).pipe(
    map(
      ([activeCompany, hasAccountingService, isAccountingServiceStateTerminated]) =>
        !!activeCompany?.isCreated && (!hasAccountingService || isAccountingServiceStateTerminated),
    ),
  );

  shouldShowAccountingReviewLink$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.isLoggedInUserAccountantOrAdmin$,
    this.isDougsCompanyAndHasDougsInvoicingFlag$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([activeCompany, isAccountantOrAdmin, isDougsCompanyAndHasDougsInvoicingFlag, services]) =>
        services.accounting.shouldShowModuleLink &&
        !!activeCompany &&
        isAccountantOrAdmin &&
        !isDougsCompanyAndHasDougsInvoicingFlag,
    ),
  );

  shouldShowCreationLink$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.isLoggedInUserAccountantOrAdmin$,
    this.userStateService.loggedInUser$,
  ]).pipe(
    map(
      ([activeCompany, isAccountantOrAdmin, loggedInUser]) =>
        !!activeCompany &&
        isAccountantOrAdmin &&
        loggedInUser.flags.includes('role:legal') &&
        !activeCompany.isCreated &&
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId),
    ),
  );

  shouldShowVendorInvoiceLink$: Observable<boolean> = combineLatest([
    this.isLoggedInUserAccountantOrAdmin$,
    this.isDougsCompanyAndHasDougsInvoicingFlag$,
    this.hasAccountingService$,
    this.isAccountingServiceStateTerminated$,
    this.isCreationMode$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([
        isAccountantOrAdmin,
        isDougsCompanyAndHasDougsInvoicingFlag,
        hasAccountingService,
        isAccountingServiceStateTerminated,
        isCreationMode,
        services,
      ]) =>
        services.accounting.shouldShowModuleLink &&
        (isAccountantOrAdmin ||
          (!isDougsCompanyAndHasDougsInvoicingFlag &&
            hasAccountingService &&
            !isAccountingServiceStateTerminated &&
            !isCreationMode)),
    ),
  );

  shouldShowSettingsLink$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.isDougsCompanyAndHasDougsInvoicingFlag$,
    this.userStateService.loggedInUser$,
  ]).pipe(
    map(
      ([activeCompany, isDougsCompanyAndHasDougsInvoicingFlag, loggedInUser]) =>
        !!activeCompany &&
        !isDougsCompanyAndHasDougsInvoicingFlag &&
        (loggedInUser.isAccountantOrAdmin || activeCompany.isCreated),
    ),
  );

  shouldShowNewSettingsLink$: Observable<boolean> = combineLatest([
    this.shouldShowSettingsLink$,
    this.userStateService.loggedInUser$,
  ]).pipe(
    map(
      ([shouldShowSettingsLink, loggedInUser]) =>
        shouldShowSettingsLink && loggedInUser.flags.includes(USER_FLAG.CAN_ACCESS_NEW_SETTINGS),
    ),
  );

  shouldShowUsersListingLink$: Observable<boolean> = combineLatest([
    this.isLoggedInUserAccountantOrAdmin$,
    this.userStateService.loggedInUser$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    map(
      ([isAccountantOrAdmin, loggedInUser, activeCompany]) =>
        !!isAccountantOrAdmin &&
        !loggedInUser?.flags.includes('restrictToAssignedCompanies') &&
        !!activeCompany &&
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId),
    ),
  );

  shouldShowInvoicingLink$: Observable<boolean> = this.companyServicesStateService.services$.pipe(
    map((services) => services.invoicing.state !== SERVICE_STATE.NOT_STARTED),
  );

  shouldShowInvestmentLink$: Observable<boolean> = combineLatest([
    this.isLoggedInUserAccountantOrAdmin$,
    this.isDougsCompanyAndHasDougsInvoicingFlag$,
    this.companyStateService.activeCompany$,
    this.hasAccountingService$,
    this.isAccountingServiceStateTerminated$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([
        isAccountantOrAdmin,
        isDougsCompanyAndHasDougsInvoicingFlag,
        activeCompany,
        hasAccountingService,
        isAccountingServiceStateTerminated,
        services,
      ]) =>
        services.accounting.shouldShowModuleLink &&
        ((isAccountantOrAdmin && !isDougsCompanyAndHasDougsInvoicingFlag) ||
          (activeCompany?.isCreated &&
            !isDougsCompanyAndHasDougsInvoicingFlag &&
            hasAccountingService &&
            !isAccountingServiceStateTerminated)),
    ),
  );

  shouldShowDeclarationLink$: Observable<boolean> = combineLatest([
    this.isLoggedInUserAccountantOrAdmin$,
    this.isDougsCompanyAndHasDougsInvoicingFlag$,
    this.companyStateService.activeCompany$,
    this.hasAccountingService$,
    this.isAccountingServiceStateTerminated$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([
        isAccountantOrAdmin,
        isDougsCompanyAndHasDougsInvoicingFlag,
        activeCompany,
        hasAccountingService,
        isAccountingServiceStateTerminated,
        services,
      ]) =>
        services.accounting.shouldShowModuleLink &&
        ((isAccountantOrAdmin && !isDougsCompanyAndHasDougsInvoicingFlag) ||
          (activeCompany?.isCreated &&
            !isDougsCompanyAndHasDougsInvoicingFlag &&
            hasAccountingService &&
            !isAccountingServiceStateTerminated)),
    ),
  );

  shouldShowPerformanceLink$: Observable<boolean> = combineLatest([
    this.isLoggedInUserAccountantOrAdmin$,
    this.isDougsCompanyAndHasDougsInvoicingFlag$,
    this.hasAccountingService$,
    this.isAccountingServiceStateTerminated$,
    this.isCreationMode$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([
        isAccountantOrAdmin,
        isDougsCompanyAndHasDougsInvoicingFlag,
        hasAccountingService,
        isAccountingServiceStateTerminated,
        isCreationMode,
        services,
      ]) =>
        services.accounting.shouldShowModuleLink &&
        (isAccountantOrAdmin ||
          (!isDougsCompanyAndHasDougsInvoicingFlag &&
            hasAccountingService &&
            !isAccountingServiceStateTerminated &&
            !isCreationMode)),
    ),
  );

  wouldSeeBlankSlate$: Observable<boolean> = combineLatest([
    this.isCreationMode$,
    this.companyServicesStateService.services$,
    this.synchronizedAccountStateService.synchronizedAccounts$,
  ]).pipe(
    map(([isCreationMode, services, synchronizedAccounts]) => {
      if (isCreationMode) {
        return false;
      }

      if (SERVICE_STATE.WAITING === services?.accounting.state) {
        return true;
      }

      if ([SERVICE_STATE.NOT_STARTED, SERVICE_STATE.TERMINATED].includes(services?.accounting.state)) {
        return false;
      }

      return !(synchronizedAccounts?.length > 0);
    }),
  );

  shouldShowAccountingSurveyLink$: Observable<boolean> = combineLatest([
    this.isDougsCompanyAndHasDougsInvoicingFlag$,
    this.hasAccountingService$,
    this.isAccountingServiceStateTerminated$,
    this.companyStateService.activeCompany$,
    this.isLoggedInUserAccountantOrAdmin$,
    this.userTasksStateService.tasks$,
    this.userStateService.loggedInUser$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([
        isDougsCompanyAndHasDougsInvoicingFlag,
        hasAccountingService,
        isAccountingServiceStateTerminated,
        activeCompany,
        isAccountantOrAdmin,
        userTasks,
        loggedInUser,
        services,
      ]) => {
        if (!services.accounting.shouldShowModuleLink) {
          return false;
        }

        if (isAccountantOrAdmin) {
          return true;
        }

        if (isDougsCompanyAndHasDougsInvoicingFlag || !hasAccountingService || isAccountingServiceStateTerminated) {
          return false;
        }

        return (
          !!activeCompany?.isCreated &&
          (isAccountantOrAdmin ||
            !!userTasks.find((task) => task.code === 'customer:accountingSurvey') ||
            loggedInUser?.flags.includes('showAccountingSurvey'))
        );
      },
    ),
  );

  shouldShowAccountingLink$: Observable<boolean> = this.companyServicesStateService.services$.pipe(
    map((services) => !!services?.accounting?.shouldShowModuleLink),
  );

  shouldShowNotificationLink$: Observable<boolean> = combineLatest([
    this.companyServicesStateService.services$,
    this.companyStateService.activeCompany$,
  ]).pipe(map(([services, company]) => !!services?.features.hasNotification && company.isCreated));

  shouldShowHelpCenterLink$: Observable<boolean> = combineLatest([
    this.userStateService.loggedInUser$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([loggedInUser, services]) =>
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId) &&
        (services.invoicing.state !== SERVICE_STATE.NOT_STARTED ||
          (services.accounting.state !== SERVICE_STATE.NOT_STARTED &&
            services.accounting.state !== SERVICE_STATE.WAITING)),
    ),
  );

  shouldShowAccountantOrAdminLink$: Observable<boolean> = combineLatest([
    this.userStateService.loggedInUser$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    map(
      ([loggedInUser, activeCompany]) =>
        !!activeCompany &&
        loggedInUser.isAccountantOrAdmin &&
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId),
    ),
  );

  shouldShowTaskLink$: Observable<boolean> = combineLatest([
    this.userStateService.loggedInUser$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    map(
      ([loggedInUser, activeCompany]) =>
        !!activeCompany &&
        loggedInUser.isAccountantOrAdmin &&
        loggedInUser.accountingFirmId !== COGEP_ACCOUNTING_FIRM_ID &&
        !loggedInUser.flags.includes('hideLegacyTaskModule'),
    ),
  );

  shouldShowSupportLink$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map(
      (loggedInUser) =>
        loggedInUser.isAccountantOrAdmin &&
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId),
    ),
  );

  shouldShowOnboardingCreationLink$: Observable<boolean> = combineLatest([
    this.companyStateService.hasCompanyCreationOnboarding$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([hasCompanyCreationOnboarding, services]) =>
        hasCompanyCreationOnboarding && services.creation.state !== SERVICE_STATE.NOT_STARTED,
    ),
  );

  shouldShowChecklistLink$: Observable<boolean> = this.accountingOnboardingStateService.accountingOnboarding$.pipe(
    map(
      (accountingOnboarding) =>
        !!accountingOnboarding?.data.checklist?.enabledAt &&
        // Don't show checklist if it has been enabled more than 2 months ago
        isBefore(addMonths(new Date(), -2), accountingOnboarding?.data.checklist?.enabledAt),
    ),
  );

  shouldShowSeparator$: Observable<boolean> = combineLatest([
    this.shouldShowSettingsLink$,
    this.shouldShowNotificationLink$,
    this.shouldShowHelpCenterLink$,
    this.userStateService.activeUserIdChanged$,
  ]).pipe(
    map(
      ([shouldShowSettingsLink, shouldShowNotificationLink, shouldShowHelpCenterLink, currentUser]) =>
        shouldShowSettingsLink ||
        shouldShowNotificationLink ||
        shouldShowHelpCenterLink ||
        currentUser.isAccountantOrAdmin,
    ),
  );
}
